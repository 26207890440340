.imageUploader {
  margin: 7px;
  img {
    width: 100%;
    height: 45px;
  }
}

.fileContainer .uploadPictureContainer {
  margin: 7px;
}

.imageThumb {
  border: 1px solid lightgrey;
  border-radius: 5px;
  display: inline-block;
  width: 100px;
  height: 100px;
  overflow: hidden;
  img {
    width: 100%;
    height: 45px;
    transform: translateY(-100%) rotate(180deg) !important;
    transform-origin: center bottom !important;
  }
}

.mrTop20 {
  margin-top: 20px;
}
