.CalendarDay__selected_span {
  border: none;
  background: #035372;
  color: white;
}

.CalendarDay__selected_end {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}

.DayPickerNavigation_button__default {
  border: none;
  transition: all 0.3s;
}

.DayPickerNavigation_button__default {
  &:hover {
    border: none;
    background: #035372;
    .DayPickerNavigation_svg__horizontal {
      fill: #fff;
    }
  }
}

.CalendarDay:hover {
  background: #035372;
  border: none;
}

.CalendarDay__selected_start {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.CalendarDay__selected {
  background: #035372;
  border: transparent;
  color: white;
}

.CalendarDay__selected:hover {
  background: #035372;
  border: none;
  color: white;
}

.CalendarDay__hovered_span:hover,
.CalendarDay__hovered_span {
  background: #035372;
  border: none;
  color: white;
}

.CalendarDay__default {
  border: none;
}

.DayPickerKeyboardShortcuts_buttonReset {
  display: none;
}

.DateInput_fang {
  display: none;
}

.DateInput_input_1,
.DateInput_input_2,
.DateRangePickerInput_arrow_svg {
  display: none;
}

.DateRangePickerInput_1 {
  width: 0px;
  height: 0px;
  border: none;
}
