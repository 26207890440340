@font-face {
  font-display: swap;
  font-family: $font-light;
  src: url("../assets/fonts/Caros-Light.eot");
  src: local("☺"), url("../assets/fonts/Caros-Light.woff") format("woff"),
    url("../assets/fonts/Caros-Light.ttf") format("truetype"),
    url("../assets/fonts/Caros-Light.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-display: swap;
  font-family: $font-medium;
  src: url("../assets/fonts/Caros-Medium.eot");
  src: local("☺"), url("../assets/fonts/Caros-Medium.woff") format("woff"),
    url("../assets/fonts/Caros-Medium.ttf") format("truetype"),
    url("../assets/fonts/Caros-Medium.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-display: swap;
  font-family: $font-normal;
  src: url("../assets/fonts/Caros.eot");
  src: local("☺"), url("../assets/fonts/Caros.woff") format("woff"),
    url("../assets/fonts/Caros.ttf") format("truetype"),
    url("../assets/fonts/Caros.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-display: swap;
  font-family: $Gilroy-normal;
  src: url("../assets/fonts/Gilroy-Medium.eot");
  src: local("☺"), url("../assets/fonts/Gilroy-Medium.woff") format("woff"),
    url("../assets/fonts/Gilroy-Medium.ttf") format("truetype"),
    url("../assets/fonts/Gilroy-Medium.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}

@import "src/scss/_variables";

body {
  margin: 0;
  padding: 0;
  font-family: $font-medium, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

p {
  color: $lightGray;
  font-size: 0.875em;
  font-family: $font-light;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  color: $black;
  font-family: $font-medium;
}
.text-bold {
  font-family: $font-medium;
}
.text-light {
  font-family: $font-medium;
}

ul {
  list-style-type: none;
  padding: 0px;
  margin: 0px;
}
.slick-cloned{
  display:none !important;
}
.no-margin {
  margin: 0 !important;
}

.no-padding {
  padding: 0 !important;
}
.no-padding-Left {
  padding-left: 0 !important;
}

a:hover {
  font-weight: 700;
  text-decoration: none;
}

body.modal-open {
  overflow: hidden !important;
}

.form-control::-webkit-input-placeholder {
  color: #c2c2c2;
}

.form-control::-moz-placeholder {
  color: #c2c2c2;
}
.form-control:-ms-input-placeholder {
  color: #c2c2c2;
}
.form-control:placeholder {
  color: #c2c2c2;
}

.Home {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.navbar-light .navbar-toggler-icon {
  background-image: url(./../assets/menu.svg) !important;
  background-color: transparent;
  cursor: pointer;
}
#FlexCol
{
  flex-grow: 0;
}
.buttonCol
{
  font-family: sans-serif;
  color: #F69400;
  background-color: transparent;
}
.navbar-brand {
  position: relative;
  z-index: 9;
}

/*==== Search Input ====*/
.car_search_2 {
  margin-top: 0 !important;
  padding: 10px;
} 
.car_search {
  margin-top: 40px;
  position: relative;
  max-width: 562px;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  .MicIcon {
    position: absolute;
    z-index: 5;
    top: 19px;
    left: 12px;
    bottom: 0;
    cursor: pointer;
  }
  .input-group {
    height: 60px;
    .form-control {
      height: 60px;
      border-bottom-left-radius: 8px;
      border-top-left-radius: 8px;
      padding-left: 35px;
    }
    .input-group-text {
      background: $primary;
      border-color: $primary;
      color: $white;
      cursor: pointer;
      border-bottom-right-radius: 8px;
      border-top-right-radius: 8px;
      padding: 0 25px;
      img {
        margin-right: 8px;
      }
    }
  }

  .bookNowBtn {
    border-width: 2px;
    background: transparent;
    border-radius: 6px;
    margin-left: 20px;
    &:hover {
      background: #035372;
    }
  }
  .typeIcon {
    font-size: 25px;
    color: #808080;
    margin-left: 10px;
  }

  .DateRangePicker {
    position: absolute;
    top: 10px;
    left: 0;
    transform: scale(0.91);
  }

  .DateRangePicker_picker {
    top: 4px !important;
  }
}

/*==== Car Card Box ====*/
.result_bookNowBtn {
  padding: 16px;
  border-radius: 6px;
}

.result_bookNowBtn_two {
  padding: 15px 31px;
}

/*==== Result Page ====*/

.loadmore_btn {
  margin: 30px;
  width: 154px;
  height: 56px;
  border-radius: 6px;
  border: solid 2px #d8d8d8;
  background-color: #fff;
  color: #888;
}
.header_result_container {
  border: none;
  margin-bottom: 0px;
  padding-bottom: 0;
}

.react-datepicker-wrapper {
  width: 50%;
}

/*==== Custom Checkbox ====*/
.custom-control-label::after,
.custom-control-label::before {
  width: 24px;
  height: 24px;
  background-color: transparent;
  border-color: #dadada;
}

.custom-control-label {
  cursor: pointer;
  padding-left: 9px;
  padding-top: 5px;
}

.custom-control-input {
  left: 0;
  width: 24px;
  height: 24px;
  top: 3px;
  &:focus {
    box-shadow: none;

    &.custom-control-input:focus ~ .custom-control-label::before {
      box-shadow: none;
      border-color: #dadada;
    }
  }
}

.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #fff;
  background-color: $primary;
  border-color: #dadada;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after,
.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: none;
  content: "\f26b";
  color: #fff;
  position: absolute;
  right: 0;
  font-family: "Material-Design-Iconic-Font";
  left: -17px;
  font-size: 14px;
  top: 6px;
}

/*====== Map Box =======*/
.mapboxgl-ctrl-bottom-left {
  display: none;
}

/*==== Custom Checkbox ====*/

.carGeneralFilters_option .custom-control {
  margin: 5px 0;
  font-family: $font-normal;
}

@media only screen and (max-width: 1024px) {
  .container {
    max-width: 960px !important;
  }
}

@media only screen and (min-width: $tablet-width) {
  .navbar-nav {
    margin: 0 auto;
  }
  .navbar-expand-md .navbar-collapse {
    position: absolute;
    width: 100%;
  }
}

/*==== Custom Tabs ====*/

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #3d3d3d !important;
}
.nav-tabs .nav-link {
  color: #959595 !important;
}
.nav-tabs:after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  height: 0.5px;
  display: block;
  background: #efefef;
  margin-top: 79px;
}

.nav-tabs .nav-link.active:after {
  position: absolute;
  content: "";
  width: 100%;
  height: 4px;
  background: #fff;
  bottom: -1px;
  z-index: 9;
}

.btn-secondary:hover {
  color: #035372;
  background-color: #fff;
  border-color: #035372;
}

.car_search {
  .button_container_custom {
    padding: 0.375rem 0.75rem;
    margin-left: 10px;
  }
}
.css-1wa3eu0-placeholder {
  color: #c2c2c2 !important;
}
// Custom Button
.button_container {
  padding: 15px;
  display: block;
  border-radius: 6px;
  color: #fff;
  border: solid 2px $primary;
  background-color: $primary;
}

.button_container_custom {
  padding: 15px;
  display: block;
  border-radius: 6px;
  color: $primary;
  border: solid 2px $primary;
  background-color: #fff;
}
textarea.form-control {
  border: none;
  resize: none;
}

.btn-primary:focus,
.btn-primary.focus {
  box-shadow: none;
}

.blogCardSlider {
  .slick-list {
    height: 190px;
  }
  .slick-slide {
    padding: 13px;
  }
}
.custom-control {
  margin-bottom: 15px;
}
.partnerLogoSlider .slick-slide {
  /*padding: 23px 31px 0;
  width: 170px;*/
  height: 7vw;
  width: 130px;
  position: relative;
  display: inline-block;
  /*border-radius: 5px;
  border: 2px solid #3bbaa8;*/
  img {
    /* height: 40px; */
    /* max-height: 90%; */
    max-width: 11vw;
    width: auto;
    height: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }
}

.carTypePanel_slider {
  .slick-list {
    height: 137px;
  }
  .slick-slide {
    padding: 10px 10px;
    width: 120px;
    height: 138px;
    img {
      width: 70px;
    }
    > div {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.slick-prev:before,
.slick-next:before {
  color: #000 !important;
}

.supplier_slider {
  .slick-track {
    width: auto !important;
    margin: 15px 0 0;
  }
  .slick-slide {
    height: 64px;
    margin-right: 15px;
    img {
      width: 70px;
    }
  }
}

.advancedCheckbox {
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #fff;
    background-color: transparent;
  }
}

button.gm-ui-hover-effect {
  display: none !important;
}
.gm-style .gm-style-iw-c {
  padding: 0;
  max-width: initial;
  background: transparent;
}

.gm-style .gm-style-iw-d {
  overflow: hidden !important;
}
@media only screen and (max-width: $mobile-width) {
  .supplier_slider {
    .slick-list {
      max-width: 700px;
      overflow-x: auto;
      margin: 0 auto;
      width: 100%;
    }
  }
  .navbar {
    padding: 0.5rem 0 1em 0.5rem;
  }
  .main_navbar {
    padding-top: 0;
  }

  .DropDown {
    .navbar-collapse {
      height: 100vh;
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: rgba(18, 18, 18, 0.98);
      width: 100%;
      z-index: 9;
      justify-content: center;
      width: 100%;
      flex-direction: column;
    }
    .navbar-light {
      .navbar-brand {
        z-index: 10;
        margin-right: 0;
      }
      .navbar-toggler {
        z-index: 10;
        outline: none;
      }
    }

    .btn-secondary {
      background: transparent;
      color: $white;
      border-radius: 50px;
      width: 100%;
    }
  }

  .car_search {
    margin-top: 40px;
    position: relative;
    max-width: 692px;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    .MicIcon {
      position: absolute;
      z-index: 5;
      top: 19px;
      left: 12px;
      bottom: 0;
      cursor: pointer;
    }
    .input-group {
      height: 60px;
      .form-control {
        height: 60px;
        border-bottom-left-radius: 8px;
        border-top-left-radius: 8px;
        padding-left: 35px;
      }
      .input-group-text {
        background: $primary;
        border-color: $primary;
        color: $white;
        cursor: pointer;
        border-bottom-right-radius: 8px;
        border-top-right-radius: 8px;
        padding: 0 10px;
        img {
          margin-right: 8px;
        }
      }
    }

    .bookNowBtn {
      border-width: 2px;
      background: transparent;
      border-radius: 6px;
      margin-left: 20px;
      &:hover {
        background: #035372;
      }
    }
    .typeIcon {
      font-size: 25px;
      color: #808080;
    }

    .DateRangePicker {
      position: absolute;
      top: 10px;
      left: 0;
      transform: scale(0.91);
      right: 0;
      > div {
        width: 320px;
        height: 330px;
        margin: 0 auto;
        position: absolute;
        right: 0;
        top: -5px;
        left: 0;
      }
    }

    .DateRangePicker_picker {
      top: 4px !important;
    }
  }

  /*==== Home Search ====*/

  .car_search {
    .input-group {
      .input-group-text {
        background: $white;
        border-color: $white;
        img {
          margin-right: 8px;
          margin-left: 8px;
          position: relative;
          z-index: 1;
        }
      }
    }
  }

  .hidden-sm {
    display: none !important;
  }
  /*==== Search Input ====*/

  .car_search {
    margin-top: 40px;
    .MicIcon {
      top: 14px;
    }
    .input-group {
      height: 48px;
      .form-control {
        height: 48px;
        border-bottom-left-radius: 4px;
        border-top-left-radius: 4px;
        border: none;
      }
      .input-group-text {
        border-bottom-right-radius: 4px;
        border-top-right-radius: 4px;
      }
    }
  }

  .car_search {
    .DateRangePicker {
      transform: scale(1.05);
    }
  }

  .partnerLogoSlider .slick-slide {
    padding: 11px;
    width: 70px;
  }

  .carGeneralFilters_option .custom-control {
    margin: 10px 0 7px;
  }

  .slick-track {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100% !important;
  }
  .result_bookNowBtn {
    padding: 12px;
  }

  .slick-next {
    right: 0px !important;
    height: 26px;
    //background: #fff !important;
    z-index: 8;
  }
  .slick-prev {
    left: 0px !important;
    height: 26px;
    //background: #fff !important;
    z-index: 8;
  }
}

.slick-next,
.slick-prev {
  top: 60%;
}

.fileContainer {
  .deleteImage {
    font-size: 16px !important;
    background: rgb(3, 83, 114) !important;
  }
}

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%;
  object-fit: cover;
  height: 210px;
  border-radius: 5px;
}

.loadmore_btn:hover {
  #TooltipExample {
    color: white !important;
    font-weight: bold;
  }
}
.button_container_custom:hover {
  #TooltipExample {
    color: white !important;
    font-weight: bold;
  }
}
.driveDropDown_container {
  margin-top: 0;
  label {
    line-height: 1.5;
  }
}
.termsModal .modal-body iframe {
  width: 100%;
  height: 440px;
  border: none !important;
}
.termsModal .modal-body object {
  width: 100%;
  height: 440px;
  border: none !important;
}

@media (min-width: 375px) and (max-width: 767px) {
    .termsModal .modal-body object {
      width: 100%;
      height: 440px;
      overflow: hidden !important;
      border: none !important;
    }

    .termsModal .modal-body iframe {
      width: 100%;
      height: 440px;
      overflow: hidden !important;
      border: none !important;
    }
}