/* .content .table table thead th {
    color: #181818;
    font-size: 14px;
    line-height: 16px;
    font-weight: 500;
} */

.content .table table thead th:first-child,
.content .table table tbody th {
	position: sticky;
	z-index: 1;
	left: 0;
	background: #fff;
	padding: 6px 9px 6px 0;
	text-align: left;
	font-size: 13px;
	font-weight: 300;
	border-left: 0;
}

.content .table table tbody td.temperature,
.content .table table tbody td.precipitation {
	min-width: 50px;
	line-height: 42px;
	background-clip: content-box;
}
.temperature.temperature-min-18 {
	background: #132778;
	color: #fff;
}
.temperature.temperature-min-16 {
	background: #253494;
	color: #fff;
}
.temperature.temperature-min-14 {
	background: #23479e;
	color: #fff;
}
.temperature.temperature-min-12 {
	background: #225ca7;
	color: #fff;
}
.temperature.temperature-min-10 {
	background: #1f76b4;
	color: #fff;
}
.temperature.temperature-min-8 {
	background: #1d91c0;
}
.temperature.temperature-min-6 {
	background: #2ca7c5;
}
.temperature.temperature-min-4 {
	background: #43b5c5;
}
.temperature.temperature-min-2 {
	background: #63c8c5;
}
.temperature.temperature-0 {
	background: #7fcdbb;
}
.temperature.temperature-plus-2 {
	background: #98dca6;
}
.temperature.temperature-plus-4 {
	background: #c7e9b4;
}
.temperature.temperature-plus-6 {
	background: #dcf2c6;
}
.temperature.temperature-plus-8 {
	background: #edf8d9;
}
.temperature.temperature-plus-10 {
	background: #f5fcd3;
}
.temperature.temperature-plus-12 {
	background: #ffc;
}
.temperature.temperature-plus-14 {
	background: #fff9b6;
}
.temperature.temperature-plus-16 {
	background: #ffeda0;
	background: #ffeda0;
}
.temperature.temperature-plus-16.night {
	background: #ffeda0;
}
.temperature.temperature-plus-18 {
	background: #ffe48b;
}
.temperature.temperature-plus-20 {
	background: #fed976;
}
.temperature.temperature-plus-22 {
	background: #fec761;
}
.temperature.temperature-plus-24 {
	background: #feb24c;
}
.temperature.temperature-plus-26 {
	background: #fea044;
}
.temperature.temperature-plus-28 {
	background: #fd8d3c;
}
.temperature.temperature-plus-30 {
	background: #fd6e33;
}
.temperature.temperature-plus-32 {
	background: #fc4e2a;
}
.temperature.temperature-plus-34 {
	background: #f23120;
}
.temperature.temperature-plus-36 {
	background: #e31a1c;
}
.temperature.temperature-plus-38 {
	background: #d20b20;
}
.temperature.temperature-plus-40 {
	background: #bd0026;
	color: #fff;
}
.temperature.temperature-plus-42 {
	background: #9f0027;
	color: #fff;
}
.temperature.temperature-plus-44 {
	background: #73001e;
	color: #fff;
}
.temperature.temperature-plus-46 {
	background: #4c0019;
	color: #fff;
}
.temperature.temperature-plus-48 {
	background: #19000c;
	color: #fff;
}
.temperature.temperature-plus-50 {
	background: #4c0099;
	color: #fff;
}
.temperature.temperature-plus-52 {
	background: #c0c;
	color: #fff;
}
.precipitation {
	background: #f4f9fe;
}
.precipitation.precipitation-0 {
	background: #f4f9fe;
}
.precipitation.precipitation-15 {
	background: #eaf4fc;
}
.precipitation.precipitation-30 {
	background: #deeffa;
}
.precipitation.precipitation-45 {
	background: #cfe8f7;
}
.precipitation.precipitation-60 {
	background: #c0e2f4;
}
.precipitation.precipitation-75 {
	background: #afd9f0;
}
.precipitation.precipitation-90 {
	background: #9fd1ec;
}
.precipitation.precipitation-105 {
	background: #8fc8e7;
}
.precipitation.precipitation-120 {
	background: #80bee1;
}
.precipitation.precipitation-135 {
	background: #71b3da;
}
.precipitation.precipitation-150 {
	background: #65a8d2;
}
.precipitation.precipitation-165 {
	background: #599ac6;
}
.precipitation.precipitation-180 {
	background: #4d8bba;
}
.precipitation.precipitation-195 {
	background: #417aab;
}
.precipitation.precipitation-210 {
	background: #35699c;
	color: #fff;
}
.precipitation.precipitation-225 {
	background: #2b588d;
	color: #fff;
}
.precipitation.precipitation-240 {
	background: #21477e;
	color: #fff;
}
.precipitation.precipitation-255 {
	background: #183870;
	color: #fff;
}
.precipitation.precipitation-270 {
	background: #102b65;
	color: #fff;
}
.precipitation.precipitation-285 {
	background: #0a215b;
	color: #fff;
}
.precipitation.precipitation-300 {
	background: #071842;
	color: #fff;
}
.content .table table tbody th,
.content .table table tbody td {
	font-size: 12px;
	line-height: 14px;
	vertical-align: middle;
	z-index: 0;
	border: 1px solid #f0f0f0;
}

.content .table table tbody td {
	color: #303030;
	text-align: center;
	padding: 3px;
	height: 48px;
	line-height: 48px;
	white-space: nowrap;
}

.location_header_banner {
	border-radius: 10px;
	padding: 15px 15px 40px;
	height: auto;
	min-height: 500px;
	background-size: cover !important;
	color: #fff;
	/* background-image: url(../../assets/location_bg.png); */
	align-items: center;
	text-align: center;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	background-position: center center !important;
	background-repeat: no-repeat;
}
.content table thead {
	border-top: 1px solid #c8ced3;
}
.content table th {
	border-bottom: none !important;
	border-top: none !important;
}
.sec6row {
	font-family: Arimo;
	font-style: normal;
	font-weight: normal;
	color: #f69400;
	padding-top: 20px;
}
.scroll {
	overflow-x: scroll;
}
.Paragraph {
	margin-left: auto;
}

@media (min-width: 1200px) {
	.scroll {
		overflow-x: hidden;
	}
}
